// React
import PropTypes from "prop-types";
import { Grid, InputLabel, TextField } from "@mui/material";

// Custom Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

export default function ExamenObjetivoGeneral({ proyecto, setProyecto }) {
  return (
    <SoftBox>
      <SoftTypography variant="h6">EXAMEN OBJETIVO GENERAL</SoftTypography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="flex-start">
          
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="peso">
                <SoftTypography component="span" variant="caption" fontWeight="bold">PESO (kg)</SoftTypography>
              </InputLabel>
              <TextField
                id="peso"
                type="number"
                value={proyecto?.peso || ''}
                onChange={(e) => setProyecto({ ...proyecto, peso: e.target.value })}
                placeholder="kg"
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="mucosas">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Mucosas tllc*</SoftTypography>
              </InputLabel>
              <TextField
                id="mucosas"
                type="text"
                value={proyecto?.mucosas || ''}
                onChange={(e) => setProyecto({ ...proyecto, mucosas: e.target.value })}
                placeholder="Seg."
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="linfonodulos">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Linfonodulos*</SoftTypography>
              </InputLabel>
              <TextField
                id="linfonodulos"
                type="text"
                value={proyecto?.linfonodulos || ''}
                onChange={(e) => setProyecto({ ...proyecto, linfonodulos: e.target.value })}
                placeholder="-"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="temperatura">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Temperatura*</SoftTypography>
              </InputLabel>
              <TextField
                id="temperatura"
                type="number"
                value={proyecto?.temperatura || ''}
                onChange={(e) => setProyecto({ ...proyecto, temperatura: e.target.value })}
                placeholder="° C"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="sensorio">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Sensorio/ fascie*</SoftTypography>
              </InputLabel>
              <TextField
                id="sensorio"
                type="text"
                value={proyecto?.sensorio || ''}
                onChange={(e) => setProyecto({ ...proyecto, sensorio: e.target.value })}
                placeholder="-"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="frecuenciaRespiratoria">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Frec. Respiratoria*</SoftTypography>
              </InputLabel>
              <TextField
                id="frecuenciaRespiratoria"
                type="number"
                value={proyecto?.frecuenciaRespiratoria || ''}
                onChange={(e) => setProyecto({ ...proyecto, frecuenciaRespiratoria: e.target.value })}
                placeholder="Resp./min"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="frecuenciaCardiaca">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Frec. Cardíaca*</SoftTypography>
              </InputLabel>
              <TextField
                id="frecuenciaCardiaca"
                type="number"
                value={proyecto?.frecuenciaCardiaca || ''}
                onChange={(e) => setProyecto({ ...proyecto, frecuenciaCardiaca: e.target.value })}
                placeholder="Lat/min"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="hidratacion">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Hidratación</SoftTypography>
              </InputLabel>
              <TextField
                id="hidratacion"
                type="text"
                value={proyecto?.hidratacion || ''}
                onChange={(e) => setProyecto({ ...proyecto, hidratacion: e.target.value })}
                placeholder="Seg."
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="consumo">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Consumo (agua/alimento)*</SoftTypography>
              </InputLabel>
              <TextField
                id="consumo"
                type="text"
                value={proyecto?.consumo || ''}
                onChange={(e) => setProyecto({ ...proyecto, consumo: e.target.value })}
                placeholder="-"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="celoGestacion">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Celo/Gestación*</SoftTypography>
              </InputLabel>
              <TextField
                id="celoGestacion"
                type="text"
                value={proyecto?.celoGestacion || ''}
                onChange={(e) => setProyecto({ ...proyecto, celoGestacion: e.target.value })}
                placeholder="-"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fechaInicio">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Fecha Inicio</SoftTypography>
              </InputLabel>
              <TextField
                id="fechaInicio"
                type="date"
                value={proyecto?.fechaInicio || ''}
                onChange={(e) => setProyecto({ ...proyecto, fechaInicio: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="fechaAlta">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Fecha Alta</SoftTypography>
              </InputLabel>
              <TextField
                id="fechaAlta"
                type="date"
                value={proyecto?.fechaAlta || ''}
                onChange={(e) => setProyecto({ ...proyecto, fechaAlta: e.target.value })}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={12}>
              <InputLabel htmlFor="observaciones">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Observaciones</SoftTypography>
              </InputLabel>
              <TextField
                id="observaciones"
                type="text"
                value={proyecto?.observaciones || ''}
                onChange={(e) => setProyecto({ ...proyecto, observaciones: e.target.value })}
                placeholder="Especificar observaciones"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>

          <SoftTypography variant="caption">
            *Si los valores son normales respecto al valor de referencia para la especie, coloque la letra (N); si no lo son, especifique el valor correspondiente.
          </SoftTypography>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

ExamenObjetivoGeneral.propTypes = {
  proyecto: PropTypes.object.isRequired,
  setProyecto: PropTypes.func.isRequired,
};
