// React
import PropTypes from "prop-types";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";

// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

export default function FormularioParasitos({ proyecto, setProyecto }) {
  return (
    <SoftBox>
      <SoftTypography variant="h6" fontWeight="bold">Formulario de Parásitos</SoftTypography>
      <Grid container spacing={2}>
        
        <Grid item xs={12}>
          <SoftTypography variant="h6" >ECTOPARÁSITOS</SoftTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="pulgas">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Pulgas</SoftTypography>
              </InputLabel>
              <SoftInput
                id="pulgas"
                type="number"
                value={proyecto?.pulgas || ''}
                onChange={(e) => setProyecto({ ...proyecto, pulgas: e.target.value })}
                placeholder="Nº"
              />
              <InputLabel htmlFor="intensidadPulgas">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Intensidad de Infestación</SoftTypography>
              </InputLabel>
              <Select
                id="intensidadPulgas"
                value={proyecto?.intensidadPulgas || ''}
                onChange={(e) => setProyecto({ ...proyecto, intensidadPulgas: e.target.value })}
                style={{ fontSize: '0.875rem' }}
              >
                <MenuItem value={1}>1 (Bajo) 1-5</MenuItem>
                <MenuItem value={2}>2 (Moderada) 6-20</MenuItem>
                <MenuItem value={3}>3 (Alta) +20</MenuItem>
                <MenuItem value="otro">Otro</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="garrapatas">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Garrapatas</SoftTypography>
              </InputLabel>
              <SoftInput
                id="garrapatas"
                type="number"
                value={proyecto?.garrapatas || ''}
                onChange={(e) => setProyecto({ ...proyecto, garrapatas: e.target.value })}
                placeholder="Nº"
              />
              <InputLabel htmlFor="intensidadGarrapatas">
                <SoftTypography component="span" variant="caption" fontWeight="bold">Intensidad de Infestación</SoftTypography>
              </InputLabel>
              <Select
                id="intensidadGarrapatas"
                value={proyecto?.intensidadGarrapatas || ''}
                onChange={(e) => setProyecto({ ...proyecto, intensidadGarrapatas: e.target.value })}
                style={{ fontSize: '0.875rem' }}
              >
                <MenuItem value={1}>1 (Bajo) 1-3</MenuItem>
                <MenuItem value={2}>2 (Moderada) 4-10</MenuItem>
                <MenuItem value={3}>3 (Alta) +10</MenuItem>
                <MenuItem value="otro">Otro</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
        <SoftTypography variant="h6">ENDOPARÁSITOS</SoftTypography>
          <InputLabel htmlFor="especiesEndoparasitos">
            <SoftTypography component="span" variant="caption" fontWeight="bold">Especies</SoftTypography>
          </InputLabel>
          <SoftInput
            id="especiesEndoparasitos"
            type="text"
            value={proyecto?.especiesEndoparasitos || ''}
            onChange={(e) => setProyecto({ ...proyecto, especiesEndoparasitos: e.target.value })}
            placeholder="Especies"
          />
          </Grid>
          <Grid item xs={12} sm={6} mt={3}>
          <InputLabel htmlFor="valorHPG">
            <SoftTypography component="span" variant="caption" fontWeight="bold">Valor de HPG</SoftTypography>
          </InputLabel>
          <SoftInput
            id="valorHPG"
            type="number"
            value={proyecto?.valorHPG || ''}
            onChange={(e) => setProyecto({ ...proyecto, valorHPG: e.target.value })}
            placeholder="VALOR DE HPG"
          />
        </Grid>

        

        <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="formulacionRecibida">
            <SoftTypography component="span" variant="caption" fontWeight="bold">Formulación Recibida*</SoftTypography>
          </InputLabel>
          <SoftInput
            id="formulacionRecibida"
            type="text"
            value={proyecto?.formulacionRecibida || ''}
            onChange={(e) => setProyecto({ ...proyecto, formulacionRecibida: e.target.value })}
            placeholder="Nombre genérico, lote y vto"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="fechaTratamiento">
            <SoftTypography component="span" variant="caption" fontWeight="bold">Fecha de Tratamiento</SoftTypography>
          </InputLabel>
          <SoftInput
            id="fechaTratamiento"
            type="date"
            value={proyecto?.fechaTratamiento || ''}
            onChange={(e) => setProyecto({ ...proyecto, fechaTratamiento: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="prueba">
            <SoftTypography component="span" variant="caption" fontWeight="bold">Prueba</SoftTypography>
          </InputLabel>
          <SoftInput
            id="prueba"
            type="text"
            value={proyecto?.prueba || ''}
            onChange={(e) => setProyecto({ ...proyecto, prueba: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="referencia">
            <SoftTypography component="span" variant="caption" fontWeight="bold">Referencia</SoftTypography>
          </InputLabel>
          <SoftInput
            id="referencia"
            type="text"
            value={proyecto?.referencia || ''}
            onChange={(e) => setProyecto({ ...proyecto, referencia: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="volumenDosis">
            <SoftTypography component="span" variant="caption" fontWeight="bold">Volumen/Dosis administrada</SoftTypography>
          </InputLabel>
          <SoftInput
            id="volumenDosis"
            type="text"
            value={proyecto?.volumenDosis || ''}
            onChange={(e) => setProyecto({ ...proyecto, volumenDosis: e.target.value })}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

FormularioParasitos.propTypes = {
  proyecto: PropTypes.object.isRequired,
  setProyecto: PropTypes.func.isRequired,
};
