// React
import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import ModalDelete from "components/Modals/Delete";
import {
  Card,
  Fade,
  Grid,
  Icon,
  Pagination,
  Skeleton,
  Tooltip,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  CircularProgress
} from "@mui/material";

// Data
// import dataUsuarios from "layouts/usuarios/data/dataUsuarios";
import dataClientes from "./data/dataClientes";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import { getUproColor } from "utils/colors";
import SoftInput from "components/SoftInput";
// import ModalUsuarios from "./ModalUsuario";
import { MessageManager } from "context";
import { UserContext } from "context/user";
// import ModalClientes from "./ModalCliente";
// import ModalDocumentacion from "./ModalDocumentacion";
import { useNavigate } from "react-router-dom";

import Cotizacion from "./components/Cotizacion";
import Fases from "./components/Fases";
import ProyectoDetalles from "./components/ProyectoDetalles";
import Medicaciones from "./components/Medicaciones";
import EventosAdversos from "./components/EventosAdversos";
import ModalEnviarPresupuesto from "./components/ModalEnviarPresupuesto";
import ModalMensajeFinal from "./components/ModalMensajeFinal";


function FormD1() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDocumentacion, setOpenModalDocumentacion] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataClientes;
  const [loadingSave, setLoadingSave] = useState(false);

  //para step

  const [step, setStep] = useState(0); 

  const [proyecto, setProyecto] = useState();

  const [proyectoOld, setProyectoOld] = useState();

  const [modalMensajeFinal,setModalMensajeFinal] = useState(false);

  const [loadingSaveAll, setLoadingSaveAll] = useState(false);

  const [textoFinal,setTextoFinal] = useState({
    titulo: "",
    descripcion: "",
  });

  const [presupuesto,setPresupuesto] = useState();
  const [openModalPresupuestoCreado, setOpenModalPresupuestoCreado] = useState(false);
  const [openModalEnviarPresupuesto, setOpenModalEnviarPresupuesto] = useState(false);
  const [openModalPresupuestoEnviado, setOpenModalPresupuestoEnviado] = useState(false);

  const steps = [
    {
      title: "1",
      icon: "description",
    },
    {
      title: "2",
      icon: "format_list_numbered",
    },
    {
      title: "3",
      icon: "format_list_numbered",
    },
    {
      title: "4",
      icon: "format_list_numbered",
    },
    {
      title: "5",
      icon: "format_list_numbered",
    },
 
  ];

  const navigate = useNavigate();

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedUsuario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteCliente] = useMutation(
    gql`
      mutation deleteCliente($id: ID!) {
        deleteCliente(id: $id) {
          id
        }
      }
    `
  );

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getClientes($search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        clientes(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          nombre
          apellido
          tipoDocumento
          documento
          domicilio
          telefono
          correo
          localidad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      {
        verificarAcceso(38) && (
          <MenuItem
            disabled={false}
            onClick={() => {
              setOpenModalAdd(true);
              handleCloseMenu();
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox>
                <SoftTypography variant="p">Editar</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: getUproColor("sistemasAmarillo"),
                  }}
                >
                  edit
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        )
      }
      {
        verificarAcceso([41,40], "or") && (
          <MenuItem
            onClick={() => {
              setOpenModalDocumentacion(true);
              handleCloseMenu();
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox>
                <SoftTypography variant="p">Documentación</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: getUproColor("sistemasGris"),
                  }}
                >
                  description
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        )
      }
     
      {verificarAcceso(39) && (
        <MenuItem
          disabled={selectedUsuario?.tipoUser === "Administrador"}
          onClick={() => {
            setOpenModalDelete(true);
            handleCloseMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox>
              <SoftTypography variant="p">Eliminar</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    selectedUsuario?.tipoUser === "Administrador"
                      ? getUproColor()
                      : getUproColor("sistemasAmarillo"),
                }}
              >
                delete
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
    </Menu>
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteCliente({ variables: { id } });
      refetch();
      handleSnackbar("Cliente eliminado correctamente", "success");
    } catch (error) {
      handleSnackbar("Error al eliminar cliente", "error");
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.clientes, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  const handleSave = async () => {
    setLoadingSave(true);
    // Tu lógica de guardado
    setLoadingSave(false);
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox pb={3}>
                {step === 0 && (
                  <ProyectoDetalles proyecto={proyecto} setProyecto={setProyecto} large />
                )}
                {step === 1 && <Fases proyecto={proyecto} setProyecto={setProyecto} large />} 
                {step === 2 && <Cotizacion proyecto={proyecto} setProyecto={setProyecto} large />}
                {step === 3 && <Medicaciones proyecto={proyecto} setProyecto={setProyecto} large />}
                {step === 4 && <EventosAdversos proyecto={proyecto} setProyecto={setProyecto} large />}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" mt={2}>
                <SoftBox
                  mr={2}
                  sx={{
                    width: {
                      sm: "100%",
                      xl: "60%",
                      xxl: "50%",
                    },
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <Tabs
                    value={step}
                    onChange={(e, value) => {
                      value <= step ? setStep(value) : null;
                    }}
                  >
                    {steps.map((thisStep, index) => (
                      <Tab
                        key={index}
                        icon={
                          <Icon
                            fontSize="small"
                            color={step === index ? "primary" : "dark"}
                            sx={{
                              verticalAlign: "middle",
                            }}
                          >
                            {thisStep.icon}
                          </Icon>
                        }
                        iconPosition="start"
                        label={
                          <SoftTypography
                            variant="body2"
                            fontWeight={step === index ? "bold" : "normal"}
                          >
                            {thisStep.title}
                          </SoftTypography>
                        }
                        sx={{
                          cursor: step < index ? "default" : "pointer",
                        }}
                      />
                    ))}
                  </Tabs>
                </SoftBox>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="end"
                  alignItems="center"
                >
                  <SoftBox
                    mr={{
                      xs: 0,
                      sm: 2,
                    }}
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="primary"
                      circular
                      onClick={step === 0 ? () => navigate("/proyectos") : () => setStep(step - 1)}
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      disabled={loadingSaveAll}
                    >
                      <Icon sx={{ fontWeight: "light" }}>
                        {step === 0 ? "close" : "arrow_back"}
                      </Icon>
                      &nbsp;{step === 0 ? "Cancelar" : "Volver"}
                    </SoftButton>
                  </SoftBox>
                  {step === 4 && (
                    <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                    mr={{
                      xs: 0,
                      sm: 2,
                    }}
                  >
                    <SoftButton
                      color="sistemasGris"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      onClick={()=>setOpenModalEnviarPresupuesto(true)}
                      /*disabled={
                        (proyectoOld && !hayCambios(proyectoOld, proyecto)) ||
                        loadingSave ||
                        loadingSaveAll ||
                        !proyecto?.nombre ||
                        !proyecto?.tipo ||
                        !proyecto?.tipoMoneda ||
                        (proyecto.tipo === "Cliente" && !proyecto?.idCliente) ||
                        (proyecto?.fechaFinEstimada &&
                          proyecto?.fechaInicioEstimada &&
                          dayjs(proyecto?.fechaInicioEstimada).isAfter(
                            dayjs(proyecto?.fechaFinEstimada)
                          )) ||
                        (proyecto?.fechaFinEstimada && !proyecto?.fechaInicioEstimada) ||
                        (presupuesto?.ocultarFases && !presupuesto?.notasExtra) ||
                        (proyecto?.etapas?.some((etapa) => proyecto?.fases?.every((fase) => fase?.idEtapa !== etapa?.id))) ||
                        (proyecto?.etapas?.some((etapa)=> !etapa?.nombre))
                      }*/
                    >
                      Visualizar y Enviar&nbsp;
                      {loadingSave ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>
                          send
                        </Icon>
                      )}
                    </SoftButton>
                  </SoftBox>
                  )}
                  <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="sistemasGris"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      onClick={step === 5 ? handleSave : () => setStep(step + 1)}
                      // disabled={
                      //   (proyectoOld && !hayCambios(proyectoOld, proyecto)) ||
                      //   loadingSaveAll ||
                      //   !proyecto?.nombre ||
                      //   !proyecto?.tipo ||
                      //   !proyecto?.tipoMoneda ||
                      //   (proyecto.tipo === "Cliente" && !proyecto?.idCliente) ||
                      //   (proyecto?.fechaFinEstimada &&
                      //     proyecto?.fechaInicioEstimada &&
                      //     dayjs(proyecto?.fechaInicioEstimada).isAfter(
                      //       dayjs(proyecto?.fechaFinEstimada)
                      //     )) ||
                      //   (proyecto?.fechaFinEstimada && !proyecto?.fechaInicioEstimada) ||
                      //   (step === 1 && (!proyecto?.fases || proyecto?.fases?.length == 0)) || 
                      //   (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.cantidad && fase?.cantidad <= 0 ))) ||
                      //   (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.nombre == "" || fase?.nombre?.trim() == ""))) ||
                      //   (presupuesto?.ocultarFases && !presupuesto?.notasExtra) ||
                      //   (proyecto?.etapas?.some((etapa) => proyecto?.fases?.every((fase) => fase?.idEtapa !== etapa?.id))) ||
                      //   (proyecto?.etapas?.some((etapa)=> !etapa?.nombre))
                      // }
                    >
                     {step === 4 ? "Guardar" : "Siguiente"} &nbsp;
                      {loadingSaveAll ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>
                          {step === 4 ? "save" : "arrow_forward"}
                        </Icon>
                      )}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        <ModalMensajeFinal
        open={modalMensajeFinal}
        handleClose={() =>{
          setModalMensajeFinal(false);
          setTextoFinal({
            titulo: "",
            descripcion: "",
          });
        }}
        titulo={textoFinal?.titulo}
        descripcion={textoFinal?.descripcion}
        />
        <ModalEnviarPresupuesto 
        open={openModalEnviarPresupuesto} 
        handleClose={() => setOpenModalEnviarPresupuesto(false)} 
        proyecto={proyecto}
        presupuesto={presupuesto}
        guardarEnviar={handleSearch}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FormD1;
