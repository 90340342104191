import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '@mui/material';
import { MessageManager } from 'context'; 
import FormularioAnimal from './formularioAnimal'; 

function App() {
  // const [open, setOpen] = useState(false);
  // const [selectedAnimal, setSelectedAnimal] = useState(null);
  // // const { handleSnackbar } = useContext(MessageManager); 

  // const handleOpen = () => {
  //   setSelectedAnimal(null); 
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const refetch = () => {
    
  //   // handleSnackbar('Datos refrescados correctamente', 'info'); 
  // };

  // return (
  //   <div>
  //     <Button variant="contained" color="primary" onClick={handleOpen}>
  //       Agregar Animal
  //     </Button>

  //     <FormularioAnimal 
  //       open={open} 
  //       handleClose={handleClose} 
  //       refetch={refetch} 
  //       selectedAnimal={selectedAnimal} 
  //     />
  //   </div>
  // );
}


ReactDOM.render(<App />, document.getElementById('root'));
