// React
import PropTypes from "prop-types";
import { Grid, InputLabel, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

export default function FormularioMedicaciones({ proyecto, setProyecto }) {
    const handleAddCanino = () => {
        const caninos = proyecto?.caninos || [];
        if (caninos.length < 5) {
            setProyecto({
                ...proyecto,
                caninos: [...caninos, { nombre: '', medicamento: '', dosis: '' }]
            });
        }
    };

    const handleAddFelino = () => {
        const felinos = proyecto?.felinos || [];
        if (felinos.length < 5) {
            setProyecto({
                ...proyecto,
                felinos: [...felinos, { nombre: '', medicamento: '', dosis: '' }]
            });
        }
    };

    const handleChangeCanino = (index, field, value) => {
        const newCaninos = [...(proyecto?.caninos || [])];
        newCaninos[index][field] = value;
        setProyecto({ ...proyecto, caninos: newCaninos });
    };

    const handleChangeFelino = (index, field, value) => {
        const newFelinos = [...(proyecto?.felinos || [])];
        newFelinos[index][field] = value;
        setProyecto({ ...proyecto, felinos: newFelinos });
    };

    return (
        <SoftBox>
            <SoftTypography variant="h6" fontWeight="bold">
                Medicaciones Recibidas en los Últimos 6 Meses
            </SoftTypography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="medicacionesRecibidas">
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            Medicaciones Recibidas (vacunas/antiparasitarios)
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                        id="medicacionesRecibidas"
                        type="text"
                        value={proyecto?.medicacionesRecibidas || ''}
                        onChange={(e) => setProyecto({ ...proyecto, medicacionesRecibidas: e.target.value })}
                        placeholder="Indicar medicaciones"
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            ¿Presenta antecedentes de reacciones de hipersensibilidad por el uso de antiparasitarios de uso externo (indicar droga y síntomas)?
                        </SoftTypography>
                    </InputLabel>
                    <Grid container spacing={1} justifyContent="flex-start">
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="hipersensibilidad"
                                name="hipersensibilidad"
                                value={proyecto?.hipersensibilidad || ''}
                                onChange={(e) => setProyecto({ ...proyecto, hipersensibilidad: e.target.value })}
                                style={{ marginLeft: '20px' }}
                            >
                                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            ¿Presenta signos de dermatitis alérgica por pulgas?
                        </SoftTypography>
                    </InputLabel>
                    <Grid container spacing={1} justifyContent="flex-start"> 
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="dermatitis"
                                name="dermatitis"
                                value={proyecto?.dermatitis || ''}
                                onChange={(e) => setProyecto({ ...proyecto, dermatitis: e.target.value })}
                                style={{ marginLeft: '20px' }}
                            >
                                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                                <FormControlLabel value="leve" control={<Radio />} label="Leve" />
                                <FormControlLabel value="moderado" control={<Radio />} label="Moderado" />
                                <FormControlLabel value="grave" control={<Radio />} label="Grave" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <SoftTypography variant="subtitle1" fontWeight="bold">
                        Animales Convivientes
                    </SoftTypography>
                    <Grid container spacing={2}>
                        {/* Sección Caninos */}
                        <Grid item xs={12}>
                            <InputLabel>
                                <SoftTypography component="span" variant="caption" fontWeight="bold">
                                    Animales Convivientes - CANINOS
                                </SoftTypography>
                            </InputLabel>
                            <Grid container spacing={1}>
                                {(proyecto?.caninos || []).map((canino, index) => (
                                    <Grid container item spacing={1} key={index}>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Nombre"
                                                value={canino.nombre}
                                                onChange={(e) => handleChangeCanino(index, 'nombre', e.target.value)}
                                                placeholder="Nombre"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Sexo"
                                                value={canino.sexo}
                                                onChange={(e) => handleChangeCanino(index, 'sexo', e.target.value)}
                                                placeholder="Sexo"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Edad"
                                                value={canino.edad}
                                                onChange={(e) => handleChangeCanino(index, 'edad', e.target.value)}
                                                placeholder="Edad"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Raza"
                                                value={canino.raza}
                                                onChange={(e) => handleChangeCanino(index, 'raza', e.target.value)}
                                                placeholder="Raza"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Peso (kg)"
                                                value={canino.peso}
                                                onChange={(e) => handleChangeCanino(index, 'peso', e.target.value)}
                                                placeholder="Peso"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Vol."
                                                value={canino.vol}
                                                onChange={(e) => handleChangeCanino(index, 'vol', e.target.value)}
                                                placeholder="Vol."
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SoftInput
                                                label="Observaciones"
                                                value={canino.observaciones}
                                                onChange={(e) => handleChangeCanino(index, 'observaciones', e.target.value)}
                                                placeholder="Observaciones"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                                {(proyecto?.caninos || []).length < 5 && (
                                    <Grid item>
                                        <SoftButton
                                            onClick={handleAddCanino}
                                            variant="outlined"
                                            color="primary"
                                            size="small" 
                                        >
                                            Agregar Canino
                                        </SoftButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        {/* Sección Felinos */}
                        <Grid item xs={12}>
                            <InputLabel>
                                <SoftTypography component="span" variant="caption" fontWeight="bold">
                                    Animales Convivientes - FELINOS
                                </SoftTypography>
                            </InputLabel>
                            <Grid container spacing={1}>
                                {(proyecto?.felinos || []).map((felino, index) => (
                                    <Grid container item spacing={1} key={index}>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Nombre"
                                                value={felino.nombre}
                                                onChange={(e) => handleChangeFelino(index, 'nombre', e.target.value)}
                                                placeholder="Nombre"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Sexo"
                                                value={felino.sexo}
                                                onChange={(e) => handleChangeFelino(index, 'sexo', e.target.value)}
                                                placeholder="Sexo"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Edad"
                                                value={felino.edad}
                                                onChange={(e) => handleChangeFelino(index, 'edad', e.target.value)}
                                                placeholder="Edad"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Raza"
                                                value={felino.raza}
                                                onChange={(e) => handleChangeFelino(index, 'raza', e.target.value)}
                                                placeholder="Raza"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Peso (kg)"
                                                value={felino.peso}
                                                onChange={(e) => handleChangeFelino(index, 'peso', e.target.value)}
                                                placeholder="Peso"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SoftInput
                                                label="Vol."
                                                value={felino.vol}
                                                onChange={(e) => handleChangeFelino(index, 'vol', e.target.value)}
                                                placeholder="Vol."
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SoftInput
                                                label="Observaciones"
                                                value={felino.observaciones}
                                                onChange={(e) => handleChangeFelino(index, 'observaciones', e.target.value)}
                                                placeholder="Observaciones"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                                {(proyecto?.felinos || []).length < 5 && (
                                    <Grid item>
                                        <SoftButton
                                            onClick={handleAddFelino}
                                            variant="outlined"
                                            color="primary"
                                            size="small" 
                                        >
                                            Agregar Felino
                                        </SoftButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SoftBox>
    );
}

FormularioMedicaciones.propTypes = {
    proyecto: PropTypes.object.isRequired,
    setProyecto: PropTypes.func.isRequired,
};
