import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Grid, Icon, InputLabel, MenuItem, Select, Divider } from "@mui/material";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import ModalAddDeposito from "layouts/productos/components/ModalSucursales/ModalAddDeposito";

const GET_CLIENTES = gql`
  query getClientes {
    clientes {
      id
      nombre
      apellido
    }
  }
`;

const GET_USUARIOS = gql`
  query getUsuarios {
    usuarios {
      id
      nombre
      apellido
    }
  }
`;

const GET_PROVINCIAS = gql`
  query GetProvincias {
    provincias {
      id
      nombre
    }
  }
`;

const GET_LOCALIDADES = gql`
  query GetLocalidades($provincia: ID!) {
    localidades(provincia: $provincia) {
      id
      nombre
    }
  }
`;

const ProyectoDetalles = ({ proyecto, setProyecto, large, readOnly }) => {
  const [modalSucursal, setModalSucursal] = useState(false);
  const { data: clientes } = useQuery(GET_CLIENTES);
  const { data: usuarios } = useQuery(GET_USUARIOS);
  const { data: provincias } = useQuery(GET_PROVINCIAS);
  const [getLocalidades, { data: localidades }] = useLazyQuery(GET_LOCALIDADES);

  useEffect(() => {
    
  }, [proyecto?.idProvincia]);

  return (
    <SoftBox>
      {large && (
        <SoftBox display="flex" justifyContent="space-between" mb={2}>
          <SoftBox>
            <SoftTypography variant="h6">Formulario Parasitos</SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="profesional">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Profesional Actuante
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Profesional Actuante"
            placeholder="Ingrese nombre del profesional"
            value={proyecto?.profesional}
            onChange={(e) => setProyecto({ ...proyecto, profesional: e.target.value })}
          />
        </Grid>

      
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="tenedor">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Tenedor Responsable
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Tenedor Responsable"
            placeholder="Ingrese nombre del tenedor responsable"
            value={proyecto?.tenedor}
            onChange={(e) => setProyecto({ ...proyecto, tenedor: e.target.value })}
          />
        </Grid>

      
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="contacto">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Contacto (tel/mail)
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Contacto"
            placeholder="Ingrese contacto"
            value={proyecto?.contacto}
            onChange={(e) => setProyecto({ ...proyecto, contacto: e.target.value })}
          />
        </Grid>

        
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="direccion">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Dirección
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Dirección"
            placeholder="Ingrese dirección"
            value={proyecto?.direccion}
            onChange={(e) => setProyecto({ ...proyecto, direccion: e.target.value })}
          />
        </Grid>

        
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="nombreAnimal">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nombre del Animal
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Nombre del Animal"
            placeholder="Ingrese nombre del animal"
            value={proyecto?.nombreAnimal}
            onChange={(e) => setProyecto({ ...proyecto, nombreAnimal: e.target.value })}
          />
        </Grid>

      
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="pelaje">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Pelaje
            </SoftTypography>
          </InputLabel>
          <Select
            value={proyecto?.pelaje || ""}
            onChange={(e) => setProyecto({ ...proyecto, pelaje: e.target.value })}
            fullWidth
          >
            <MenuItem value="Corto">Corto</MenuItem>
            <MenuItem value="Medio">Medio</MenuItem>
            <MenuItem value="Largo">Largo</MenuItem>
          </Select>
        </Grid>

        
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="especie">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Especie
            </SoftTypography>
          </InputLabel>
          <Select
            value={proyecto?.especie || ""}
            onChange={(e) => setProyecto({ ...proyecto, especie: e.target.value })}
            fullWidth
          >
            <MenuItem value="Canino">Canino</MenuItem>
            <MenuItem value="Felino">Felino</MenuItem>
          </Select>
        </Grid>

        {/* Sexo */}
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="sexo">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Sexo
            </SoftTypography>
          </InputLabel>
          <Select
            value={proyecto?.sexo || ""}
            onChange={(e) => setProyecto({ ...proyecto, sexo: e.target.value })}
            fullWidth
          >
            <MenuItem value="Macho">Macho</MenuItem>
            <MenuItem value="Hembra">Hembra</MenuItem>
          </Select>
        </Grid>

       
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="raza">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Raza
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Raza"
            placeholder="Ingrese raza"
            value={proyecto?.raza}
            onChange={(e) => setProyecto({ ...proyecto, raza: e.target.value })}
          />
        </Grid>

       
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="edad">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Edad
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="Edad"
            placeholder="Ingrese edad"
            value={proyecto?.edad}
            onChange={(e) => setProyecto({ ...proyecto, edad: e.target.value })}
          />
        </Grid>

        
        <Grid item xs={12} sm={6}>
          <InputLabel htmlFor="foto">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Foto
            </SoftTypography>
          </InputLabel>
          <SoftBox display="flex" alignItems="center">
            <SoftButton variant="outlined" color="primary" onClick={() => setModalSucursal(true)}>
              <Icon>add</Icon>
              Agregar Foto
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>

      <ModalAddDeposito open={modalSucursal} handleClose={() => setModalSucursal(false)} />
    </SoftBox>
  );
};

ProyectoDetalles.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default ProyectoDetalles;
