// React
import PropTypes from "prop-types";
import { Grid, InputLabel, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

export default function FormularioEventosAdversos({ proyecto, setProyecto }) {
    const handleAddEvento = () => {
        const eventos = proyecto?.eventos || [];
        setProyecto({
            ...proyecto,
            eventos: [...eventos, { fecha: '', descripcion: '', severidad: '', accion: '', notificador: '' }]
        });
    };

    const handleChangeEvento = (index, field, value) => {
        const newEventos = [...(proyecto?.eventos || [])];
        newEventos[index][field] = value;
        setProyecto({ ...proyecto, eventos: newEventos });
    };

    return (
        <SoftBox>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <SoftTypography variant="subtitle1" fontWeight="bold" align="left">
                        Registro de Eventos Adversos
                    </SoftTypography>
                    <Grid container spacing={1}>
                        {(proyecto?.eventos || []).map((evento, index) => (
                            <Grid container item spacing={1} key={index} alignItems="center">
                                <Grid item xs={3}>
                                    <SoftInput
                                        label="Fecha de ocurrencia"
                                        type="date"
                                        value={evento.fecha}
                                        onChange={(e) => handleChangeEvento(index, 'fecha', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <SoftInput
                                        label="Descripción del evento"
                                        value={evento.descripcion}
                                        onChange={(e) => handleChangeEvento(index, 'descripcion', e.target.value)}
                                        placeholder="Descripción"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel>Severidad</InputLabel>
                                    <RadioGroup
                                        row
                                        value={evento.severidad}
                                        onChange={(e) => handleChangeEvento(index, 'severidad', e.target.value)}
                                        style={{ marginLeft: '20px' }} 
                                    >
                                        <FormControlLabel value="L" control={<Radio />} label="Leve" />
                                        <FormControlLabel value="M" control={<Radio />} label="Moderado" />
                                        <FormControlLabel value="G" control={<Radio />} label="Grave" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={5}>
                                    <SoftInput
                                        label="Acción tomada"
                                        value={evento.accion}
                                        onChange={(e) => handleChangeEvento(index, 'accion', e.target.value)}
                                        placeholder="Acción tomada"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SoftInput
                                        label="Notificador"
                                        value={evento.notificador}
                                        onChange={(e) => handleChangeEvento(index, 'notificador', e.target.value)}
                                        placeholder="Notificador"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <SoftButton
                                onClick={handleAddEvento}
                                variant="outlined"
                                color="primary"
                                size="small" 
                                style={{ marginTop: '16px' }} 
                            >
                                Agregar Evento
                            </SoftButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            Posible relación con el tratamiento
                        </SoftTypography>
                    </InputLabel>
                    <RadioGroup
                        row
                        value={proyecto?.posibleRelacion || ''}
                        onChange={(e) => setProyecto({ ...proyecto, posibleRelacion: e.target.value })}
                        style={{ marginLeft: '20px' }} 
                    >
                        <FormControlLabel value="si" control={<Radio />} label="Sí" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            Exclusión del animal del estudio
                        </SoftTypography>
                    </InputLabel>
                    <RadioGroup
                        row
                        value={proyecto?.exclusion || ''}
                        onChange={(e) => setProyecto({ ...proyecto, exclusion: e.target.value })}
                        style={{ marginLeft: '20px' }} 
                    >
                        <FormControlLabel value="si" control={<Radio />} label="Sí" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <SoftTypography variant="subtitle1" fontWeight="bold">
                        Tratamientos Concomitantes
                    </SoftTypography>
                    <SoftInput
                        label="Especificar medicaciones recibidas"
                        value={proyecto?.tratamientosConcomitantes || ''}
                        onChange={(e) => setProyecto({ ...proyecto, tratamientosConcomitantes: e.target.value })}
                        placeholder="Especificar medicaciones"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <SoftTypography variant="subtitle1" fontWeight="bold">
                        Alta Clínica
                    </SoftTypography>
                    <SoftInput
                        label="Detalles del alta"
                        value={proyecto?.altaClinica || ''}
                        onChange={(e) => setProyecto({ ...proyecto, altaClinica: e.target.value })}
                        placeholder="Detalles del alta"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <SoftTypography variant="subtitle1" fontWeight="bold">
                        Observaciones Generales
                    </SoftTypography>
                    <SoftInput
                        label="Observaciones"
                        value={proyecto?.observacionesGenerales || ''}
                        onChange={(e) => setProyecto({ ...proyecto, observacionesGenerales: e.target.value })}
                        placeholder="Observaciones generales"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </SoftBox>
    );
}

FormularioEventosAdversos.propTypes = {
    proyecto: PropTypes.object.isRequired,
    setProyecto: PropTypes.func.isRequired,
};
