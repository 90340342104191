// React
import { useContext, useState } from "react";

// Components
import { Card, Grid, Icon, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Data
import SinDatos from "components/Carteles/SinDatos";
import SoftInput from "components/SoftInput";
import ModalProveedores from "./ModalProveedores";
import { UserContext } from "context/user";
import ModalSolicitudes from "./components/ModalSolicitudes";

function Compras() {
  const [openModalProveedores, setOpenModalProveedores] = useState(false);
  const [openModalSolicitudes, setOpenModalSolicitudes] = useState(false);
  const { verificarAcceso } = useContext(UserContext);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Cotizaciones</SoftTypography>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                      />
                    </SoftBox>
                    {verificarAcceso(30) && (
                      <SoftBox ml={2}>
                        <Tooltip title="Administrar proveedores" placement="top">
                          <SoftButton
                            color="sistemasAmarillo"
                            onClick={() => {
                              setOpenModalProveedores(true);
                            }}
                            circular
                          >
                            <Icon sx={{ fontWeight: "light" }}>group</Icon>
                            &nbsp;Proveedores
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    )}
                    {verificarAcceso(37) && (
                      <SoftBox ml={2}>
                        <Tooltip title="Solicitudes de compra" placement="top">
                          <SoftButton
                            color="sistemasAmarillo"
                            onClick={() => {
                              setOpenModalSolicitudes(true);
                            }}
                            circular
                          >
                            <Icon sx={{ fontWeight: "light" }}>attach_money</Icon>
                            &nbsp;Compras
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {verificarAcceso(101) ? (
                <Grid container textAlign={"center"} py={3}>
                  <Grid item xs={12}>
                    <Icon
                      sx={{
                        width: "50px",
                      }}
                    >
                      folder_open
                    </Icon>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6">No hay licitaciones disponibles</SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="caption" component="p" color="text">
                      No hay licitaciones para mostrar
                    </SoftTypography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container textAlign={"center"} py={3}>
                  <Grid item xs={12}>
                    <Icon
                      sx={{
                        width: "50px",
                      }}
                      fontSize="large"
                    >
                      schedule
                    </Icon>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6">
                      Aún no puede participar en licitaciones
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="caption" component="p" color="text">
                      Su perfil está en proceso de aprobación, una vez aprobado podrá participar en
                      las licitaciones
                    </SoftTypography>
                  </Grid>
                </Grid>
              )}
            </SoftBox>
          </Card>
          <ModalProveedores
            open={openModalProveedores}
            handleClose={() => setOpenModalProveedores(false)}
          />
          <ModalSolicitudes 
          open={openModalSolicitudes}
          handleClose={() => setOpenModalSolicitudes(false)}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Compras;
